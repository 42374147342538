import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import AddStudent from './components/AddStudent';
import SearchStudent from './components/SearchStudent';
import Alerts from './components/Alerts';
import ExportData from './components/ExportData';

function App() {
  return (
    <Router basename="/Church/SGSA/confession">
      <div>
        <h1>Abouna System</h1>
        <nav>
          <ul>
            <li><Link to="/add-student">Add Student</Link></li>
            <li><Link to="/log-attendance">Log Attendance</Link></li>
            <li><Link to="/alerts">View Alerts</Link></li>
            <li><Link to="/export">Export Data</Link></li>
          </ul>
        </nav>
        <Routes>
          <Route path="/add-student" element={<AddStudent />} />
          <Route path="/log-attendance" element={<SearchStudent />} />
          <Route path="/alerts" element={<Alerts />} />
          <Route path="/export" element={<ExportData />} />
          <Route path="/" element={<h2>Welcome to Abouna System</h2>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;