import React, { useState } from 'react';
import axios from 'axios';

function AddStudent() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [grade, setGrade] = useState('Grade 1');

  const handleSubmit = (e) => {
    e.preventDefault();
    const apiUrl = `http://208.109.241.2/api/add-member`;
    axios.post(apiUrl, {
      firstName,
      lastName,
      grade
    })
    .then(response => {
      console.log(response.data);
      alert('Student added successfully!');
    })
    .catch(error => {
      console.error(error);
      alert('Error adding student');
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>First Name:</label>
        <input
          type="text"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </div>
      <div>
        <label>Last Name:</label>
        <input
          type="text"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </div>
      <div>
        <label>Grade:</label>
        <select value={grade} onChange={(e) => setGrade(e.target.value)}>
          <option value="Grade 1">Grade 1</option>
          <option value="Grade 2">Grade 2</option>
          <option value="Grade 3">Grade 3</option>
          <option value="Grade 4">Grade 4</option>
          <option value="Grade 5">Grade 5</option>
          <option value="Grade 6">Grade 6</option>
          <option value="Grade 7">Grade 7</option>
          <option value="Grade 8">Grade 8</option>
          <option value="Grade 9">Grade 9</option>
          <option value="Grade 10">Grade 10</option>
          <option value="Grade 11">Grade 11</option>
          <option value="Grade 12">Grade 12</option>
          <option value="University">University</option>
          <option value="Graduates">Graduates</option>
          <option value="Prep for marriage">Prep for marriage</option>
          <option value="Newly wed">Newly wed</option>
          <option value="Adults">Adults</option>
          <option value="Youth Follow up">Youth Follow up</option>
          <option value="Adults Follow up">Adults Follow up</option>
        </select>
      </div>
      <button type="submit">Add Student</button>
    </form>
  );
}

export default AddStudent;
