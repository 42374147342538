import React from 'react';
import axios from 'axios';

function ExportData() {
  const handleExport = () => {
    const apiUrl = `http://208.109.241.2/api/export`;
    axios.get(apiUrl)
      .then(response => {
        alert('Data exported successfully!');
      })
      .catch(error => {
        console.error(error);
        alert('Error exporting data');
      });
  };

  return (
    <div>
      <h2>Export Data</h2>
      <button onClick={handleExport}>Export to Google Sheets</button>
    </div>
  );
}

export default ExportData;
