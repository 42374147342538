import React, { useState } from 'react';
import axios from 'axios';

function SearchStudent() {
  const [name, setName] = useState('');
  const [students, setStudents] = useState([]);

  const handleSearch = () => {
    const apiUrl = `http://208.109.241.2/api/students?name=${name}`;
    axios.get(apiUrl)
    .then(response => {
      setStudents(response.data);
    })
    .catch(error => {
      console.error(error);
      alert('Error fetching students');
    });
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Search by name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <button onClick={handleSearch}>Search</button>
      <ul>
        {students.map(student => (
          <li key={student.id}>
            {student.first_name} {student.last_name} - Grade: {student.grade}
            <button onClick={() => logAttendance(student.id)}>Log Attendance</button>
          </li>
        ))}
      </ul>
    </div>
  );

  function logAttendance(studentId) {
    const apiUrl = `http://208.109.241.2/api/log-attendance`;
    axios.post(apiUrl, { studentId })
      .then(response => {
        alert('Attendance logged successfully');
      })
      .catch(error => {
        console.error(error);
        alert('Error logging attendance');
      });
  }
}

export default SearchStudent;
